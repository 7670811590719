<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm7 md5>
                <v-card class="elevation-6" style="max-width: 500px;margin: 0% auto">
                    <v-toolbar dense dark color="primary">
                        <v-toolbar-title>注册</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field prepend-icon="person" name="username" label="用户名" type="email"
                                :rules="[rules.required, rules.counter]" v-model="username">
                            </v-text-field>
                            <v-text-field prepend-icon="email" name="email" label="邮箱" type="email"
                                :rules="[rules.required, rules.email]" v-model="email">
                            </v-text-field>
                            <v-text-field id="password" prepend-icon="lock" name="password" label="密码" type="password"
                                :rules="[rules.required, rules.counter]" v-model="password" maxlength=12></v-text-field>
                            <v-text-field id="password2" prepend-icon="lock" name="password2" label="重复密码"
                                :rules="[rules.required, rules.counter]" v-model="password2" type="password"
                                maxlength=12></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" to='/auth/signin'>去登录</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="EventSignUp">确认</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import { event_emit } from '@/eventbus'

    export default {
        components: {},
        data() {
            return {
                username: '',
                email: '',
                password: '',
                password2: '',
                rules: {
                    required: value => !!value || '必须',
                    counter: value => value.length <= 12 && value.length >= 6 || '6 ~ 12个字符',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || '请检查邮件地址'
                    }
                }
            }
        },
        props: [],
        methods: {
            EventSignUp() {
                if (!this.password || !this.password2 || !this.username || !this.email) {
                    event_emit(this, 'snackbar', { type: 'warning', msg: '请检查填写的项目' });
                    return
                }
                if (this.password.length < 6 || this.password.length > 12) {
                    event_emit(this, 'snackbar', { type: 'warning', msg: '密码要求6 ~ 12个字符' });
                    return
                }
                if (this.email) {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!pattern.test(this.email)) {
                        event_emit(this, 'snackbar', { type: 'warning', msg: '请检查邮件地址' });
                        return
                    }
                }

                if (this.password !== this.password2) {
                    event_emit(this, 'snackbar', { type: 'warning', msg: '两次密码必须相同' });
                    return
                }
                // event.signup(this, postdata);
            },
        },
        mounted: function () { },
        beforeDestroy() {
            this.password = null
            this.username = null
            this.email = null
            this.rules = null
        },
    }
</script>
<style scoped>
</style>